<template>
	<div class="mx-auto overflow-hidden text-sm maxWidth lg:text-base">

		<!-- Header -->
		<div class="section-header border-t-[10px] border-activa-blue-2 w-full duration-500 z-50 absolute py-5 sm:py-4">
			<div class="container mx-auto px-4 lg:px-[85px]">
				<div class="flex flex-col flex-wrap items-center justify-center sm:flex-row sm:justify-between">
					<div class="w-auto">
						<img
							src="/images/logo.png"
							alt="Activa Media"
							class="mb-5 pr-0 md:mb-0 sm:pr-10 h-[60px] lg:h-auto"
						/>
					</div>
					<div class="flex flex-wrap">
						<a href="tel:+6563860907" id="cta-top-desktop"
							class="block px-6 py-2 text-sm lg:text-base text-center rounded-full montserrat-bol am-blue border-[#4678b4] lg:border-0 bg-white border-2"
							style="letter-spacing: 1px;"
						>
							Call 6386 0907
						</a>
					</div>
				</div>
			</div>
		</div>


		<div class="justify-center lg:flex lg:flex-wrap">
			<div
				class="bg-left-bottom bg-no-repeat bg-contain lg:w-10/12 lg:h-screen bg-banner-wrap"
				style="background-image: url(../images/bg-activa.png)"
			>
				<div class="XpaddingLeft lg:absolute lg:w-full">

					<div class="container mx-auto px-4 lg:px-[85px]">
						<div
							class="pt-[230px] pb-10 lg:pt-[138px] montserrat-bol lg:pb-40 lg:h-screen lg:tracking-[-1px] lg:w-7/12 bg-banner-inner"
						>
							<div
								class="mb-[5px] text-3xl leading-tight lg:leading-tight md:text-4xl lg:text-[40px] xl:text-5.5xl"
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								Performance-Driven
							</div>
							<h1
								class="text-4xl leading-none md:text-5xl lg:pr-16 xl:text-[70px] am-blue lg:leading-none mb-5 lg:mb-10"
								data-aos="fade-up"
								data-aos-duration="1000"
								data-aos-delay="500"
							>
								Digital
								<span class="lg:block">Marketing</span>
								Agency
							</h1>
							<h2
								class="mb-5 text-xl leading-tight lg:text-[32px]"
								data-aos="fade-up"
								data-aos-duration="1000"
								data-aos-delay="1000"
							>
								Generate Qualified Leads
								<span class="lg:block">& Build Lifetime Value</span>
							</h2>
							<p
								class="mb-10 text-base tracking-normal lg:text-xl lg:leading-normal montserrat-reg"
								data-aos="fade-right"
								data-aos-duration="1000"
								data-aos-delay="2000"
							>
								Best Digital Marketing Solutions,<br>Brought To You By Activa Media, Est.<br>Since 2004 

								
							</p>
							<!-- google partner  -->
							<div
								class="space-y-10 md:flex md:items-center md:justify-center lg:justify-between lg:space-y-0"
							>
								<a
									v-scroll-to="{ el: '#free-trial', duration: 1500 }"
									class="inline-block lg:block px-5 py-[13px] mt-[2px] mx-auto text-[15px] tracking-[3px] text-center text-white uppercase bg-[#3cb4f0] rounded-full md:mx-0 lg:mx-0 montserrat-med cursor-pointer"
									>ENQUIRE NOW</a
								>

								

							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="hidden bg-right bg-no-repeat bg-cover md:w-11/12 lg:block"
				style="background-image: url(/images/bg-header-02.jpg)"
			>
			</div>
		</div>
		<!-- Header End -->

		<!-- How We Help You -->
		<div class="pt-10 lg:pt-20 pb-5 lg:pb-[60px] bg-[#E6F5FA]">
			<div class="container mx-auto px-4 lg:px-[85px]">
				<h2 class="mb-5 text-3xl text-center lg:text-[40px] lg:text-left lg:leading-tight montserrat-bol lg:tracking-[-1px]">
					How We <span class="am-blue">Help You</span>
				</h2>
				<p class="mb-10 text-center lg:px-0 lg:text-left montserrat-reg">
					Your goals are our goals. With a combination of our experience, reach and array of services, we make digital marketing easy for you.
				</p>
				<div class="grid-cols-3 mb-5 md:grid md:justify-center gap-x-[30px] space-y-10 lg:space-y-0">
					<div
						class=""
						v-for="(item, i) in helpU"
						:key="i"
						:data-aos="item.aniMation"
						:data-aos-delay="item.deLay"
						data-aos-anchor-placement="top-bottom"
						data-aos-duration="1200"
					>
						<div class="flex items-center mb-5 lg:block lg:mb-0 lg:space-y-5">
							<img class="w-auto h-20 lg:h-auto mr-[10px] lg:mr-0" :src="item.image" :alt="item.alt" />
							<p class="text-xl lg:text-2xl lg:leading-[1.25] montserrat-bol">{{ item.alt }}</p>
						</div>
						<p class="montserrat-reg lg:mt-5">{{ item.sub }}</p>
					</div>
				</div>
			</div>
		</div>
		<!-- How We Help You End -->

		<!-- Our Specialties -->
		<div id="our-specialities" class="pt-10 lg:pt-20 pb-5 lg:pb-[60px] scroll-mt-32">
			<div class="container mx-auto px-4 lg:px-[85px] lg:text-center">
				<h2 class="mb-5 text-3xl lg:text-[40px] lg:text-left lg:leading-tight montserrat-bol lg:tracking-[-1px]" data-aos="fade-right" data-aos-duration="1000">
					Our <span class="am-blue">Specialties</span>
				</h2>
				<p class="mb-10 lg:text-left montserrat-reg">
					Activa Media specialises in providing online advertising using Google, Facebook and Tik Tok. We tailor each campaign with bulletproof strategies for measurable results and lead generation. Find out more how you can increase your revenue with our methods to stellar online advertising.
				</p>

				<div class="grid lg:grid-cols-3 mb-5 gap-x-[30px] gap-y-10 text-center">
					<div class="">
						<div class="specialty-content" data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
							<img class="mx-auto mb-5" src="/images/logo-specialty-01.jpg"/>
							<h4 class="montserrat-bol text-xl lg:text-2xl lg:leading-[1.25] lg:pt-[3px]">Search Engine <span class="lg:block">Marketing (SEM)</span></h4>
						</div>
					</div>
					<div class="">
						<div class="specialty-content" data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-duration="1000" data-aos-delay="300">
							<img class="mx-auto mb-5" src="/images/logo-specialty-02.jpg"/>
							<h4 class="montserrat-bol text-xl lg:text-2xl lg:leading-[1.25] lg:pt-[3px]">Social Media <span class="lg:block">Marketing (SMM)</span></h4>
						</div>
					</div>
					<div class="">
						<div class="specialty-content" data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-duration="1000" data-aos-delay="600">
							<img class="mx-auto mb-5" src="/images/logo-specialty-03.jpg"/>
							<h4 class="montserrat-bol text-xl lg:text-2xl lg:leading-[1.25] lg:pt-[3px]">Search Engine <span class="lg:block">Optimization (SEO)</span></h4>
						</div>
					</div>
				</div>

			</div>
		</div>
		<!-- Our Specialties End -->

		<!-- There’s no better time -->
		<!-- <div class="mx-auto maxWidth">
			<div class="justify-center lg:flex lg:flex-wrap">
				<div
					class="h-64 bg-center bg-no-repeat bg-cover lg:w-1/2 lg:h-auto"
					style="background-image: url(../images/bg-start.jpg)"
				></div>
				<div class="py-16 pl-12 text-white lg:w-1/2 am-blue-bg">
					<div class="pr-6 xl:w-3/5 lg:w-3/5 lg:pr-0">
						<h1
							class="text-4xl leading-tight montserrat-bol"
							data-aos="fade-right"
						>
							There’s no better time than now.
						</h1>
						<br />
						<p class="pr-6 montserrat-reg lg:pr-0" data-aos="fade-right">
							In marketing, time lost is compounded as your competitors move
							ahead of you. Each customer you lose is one they gain.
							<br /><br />
							That’s why it’s important to reach them – fast! We’re ready to
							help you get there.
							<br /><br />
							<span class="font-bold"
								>Arrange for a video call with our specialists, and you’ll be
								provided with a fully customized, no-obligations plan of action
								for your business to grow.</span
							>
						</p>
						<br /><br />
						<a
							href="#"
							v-scroll-to="{ el: '#element', duration: 1500 }"
							data-aos="fade-up"
							class="block w-56 px-5 py-3 text-sm tracking-widest text-center text-white uppercase bg-blue-400 rounded-full montserrat-reg"
							>LET’S GET STARTED</a
						>
					</div>
				</div>
			</div>
		</div> -->
		<!-- There’s no better time End -->

		<!-- Transition CTA -->
		<div class="py-[60px] text-center bg-[#f5f8fa]">
			<div class="container mx-auto">
				<div class="">
					<h3 class="mb-5 text-3xl lg:text-[40px] lg:leading-tight montserrat-bol lg:tracking-[-1px]" data-aos="fade-up" data-aos-duration="1000">
						<span class="am-blue">Creating a positive experience</span> is our top priority.
					</h3>
					<div class="mb-5 montserrat-reg" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="250">
						<p>Get your Free 14-Day Google Trial* plan.</p>
					</div>
					<div class="">
						<a class="inline-block px-5 py-[15px] mx-auto text-sm lg:text-[15px] tracking-[3px] text-center text-white uppercase bg-[#3cb4f0] rounded-full md:mx-0 lg:mx-0 montserrat-med cursor-pointer" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" style="margin-bottom:10px" v-scroll-to="{ el: '#free-trial', duration: 1500 }">
							ENQUIRE NOW
						</a>
					</div>
				</div>
			</div>
		</div>
		<!-- Transition CTA End -->

		<!-- Success Stories and counting -->
		<div id="client-success" class="pt-10 lg:pt-20 pb-5 lg:pb-[60px] am-blue-bg scroll-mt-32">
			<div class="container mx-auto px-4 lg:px-[85px]">
				<div class="justify-center lg:grid grid-cols-2 gap-x-[30px]">
					<div class="space-y-5 montserrat-bol">
						<h2 class="text-5xl conterfont am-blue-dark lg:tracking-[-1px]">
							<CounterCount />
						</h2>
						<p class="text-3xl lg:text-4xl lg:leading-tight montserrat-bol lg:tracking-[-1px] text-white">
							Success Stories <span class="am-blue-dark">and counting</span>
						</p>
						<p class="lg:!mt-10 text-2xl lg:text-[28px] lg:leading-tight text-white">
							Trusted by the best in the business
						</p>
						<div class="text-white montserrat-reg space-y-5 !mb-6">
							<p>Over the years, we’ve helped thousands of businesses launch more than 6,500 campaigns.</p>
							<p>By intimately understanding your business and industry, we design campaigns that show you for who you truly are.</p>
						</div>
					</div>
					<div class="lg:pl-[45px] mt-[30px] mb-5">
						<div class="text-white border-b border-white border-solid" v-for="(item, i) in industry" :key="i" @click="item.open = !item.open">
							<button class="flex items-center w-full text-left xcusWidth">
								<img :src="item.image" :alt="item.alt" class="col-auto" />
								<div class="pl-5 py-[15px] w-full text-base lg:text-xl montserrat-med leading-[30px]">
									{{ item.alt }}
								</div>
								<div class="justify-self-end">
									<div class="rounded-full bg-white leading-none text-lg text-center montserrat-bol h-5 w-5 text-[#4678B4]">
										{{ item.open ? '–' : '+' }}
									</div>
								</div>
							</button>
							<collapse-transition>
								<div v-show="item.open" class="pt-5 pb-10 montserrat-reg">
									{{ item.text }}
								</div>
							</collapse-transition>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Success Stories and counting End -->

		<!-- Testi -->
		<div class="pt-10 lg:pt-20 pb-5 lg:pb-[60px]">
			<div class="container mx-auto px-4 lg:px-[85px]">
				<h2 class="mb-5 text-3xl lg:text-[40px] lg:leading-tight montserrat-bol lg:tracking-[-1px] text-center">
					<span class="am-blue">Satisfied Clients</span> are our Best Ads
				</h2>
				<br />
				<TheTesti />
			</div>
		</div>
		<!-- Testi End -->

		<!-- Why Choose Us -->
		<div id="activa-media" class="pt-10 lg:pt-20 pb-10 lg:pb-[60px] bg-[#F5F8FA] scroll-mt-32">
			<div class="container mx-auto px-4 lg:px-[85px]">
				<ChooseUs class="" />
			</div>
		</div>
		<!-- Why Choose Us End -->

		<!-- Businesses  -->
		<div id="choose-activa-media" class="pt-10 lg:pt-20 pb-5 lg:pb-[60px] bg-white scroll-mt-32">
			<div class="container mx-auto px-4 lg:px-[85px] text-center space-y-5">
				<h2 class="text-3xl lg:text-[40px] lg:leading-tight montserrat-bol lg:tracking-[-1px]" data-aos="zoom-in-down" data-aos-duration="1000" data-aos-delay="200">
					<span class="am-blue">Trusted</span> By Partners, <span class="am-blue">Loved By</span> Businesses
				</h2>
				<div class="space-y-5" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1500" data-aos-delay="600">
					<p class="text-sm montserrat-reg lg:text-base">
						Working with businesses across 106 different industries, we have the expertise and capabilities to grow all businesses
					</p>
					<div>
						<picture>
							<source type="image/jpeg" media="(min-width:768px)" srcset="/images/client-img.jpg" width="1266" height="408">
							<img src="/images/client-img-mobile.jpg" width="480" height="780">
						</picture>
					</div>
				</div>
			</div>
		</div>
		<!-- Businesses End -->

		<!-- Enquiry  -->
		<div id="free-trial" class="pt-10 lg:pt-20 pb-5 lg:pb-[60px] bg-[#E6F5FA] scroll-mt-32">
			<div class="container mx-auto px-4 lg:px-[85px] text-center space-y-5">
				<h2 class="text-3xl lg:text-[40px] lg:leading-tight montserrat-bol lg:tracking-[-1px]" data-aos="zoom-in-down" data-aos-duration="1000" data-aos-delay="200">
					<span class="am-blue">Free 14-Day</span>
					Google Trial Available
				</h2>
				<div class="space-y-5" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1500" data-aos-delay="600">
					<p class="text-sm montserrat-reg lg:text-base">
						Drop us a message and we’ll get back to you within 1 working day.
					</p>
				</div>
				<div class="enquiry">
					<div id="xcustomHeight" class="mx-auto">
						<EnquiryForm></EnquiryForm>
					</div>
				</div>
			</div>
		</div>
		<!-- Enquiry End -->

		<!-- Contact Us -->
		<div class="pt-10 lg:pt-20 pb-5 lg:pb-[60px] am-dark-bg">
			<div class="container mx-auto px-4 lg:px-[85px]">
				<div class="items-center justify-center mb-5 md:flex md:flex-wrap">
					<div class="mb-5 md:w-1/2 lg:mb-0">
						<img class="w-full h-auto" width="555" height="312" src="/images/amsg-profile-opt.jpg" alt="Activa Media SG">
					</div>
					<div class="space-y-5 text-white md:pl-4 lg:pl-16 md:w-1/2">
						<div class="flex items-center gap-x-8">
							<img
								src="/images/logo-white.png"
								alt="Activa Media"
								class="mx-auto lg:mx-0"
							/>
						</div>
						<p class="text-sm lg:text-base montserrat-reg">
							60 Paya Lebar Road
							<span class="block">#12-51/52, Paya Lebar Square</span>
							<span class="block">Singapore 409051</span>
						</p>
						<div class="">
							<a
								href="tel:+6563860907"
								id="cta-bottom"
								class="block text-sm montserrat-reg lg:text-base">
								<i class="fas fa-building"></i> <span>6386 0907</span>
							</a>
							<!-- <a
								href="mailto:enquiry@activamedia.com.sg"
								class="block text-sm montserrat-reg lg:text-base">
								<i class="fas fa-envelope"></i> enquiry@activamedia.com.sg
							</a> -->
						</div>
						<iframe class="!mt-[30px] md:w-[310px] md:h-[177px]" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.046036029364!2d103.8927741!3d1.318771!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19005dbc393b%3A0x53b20604f82e0258!2sBest%20Digital%20Marketing!5e0!3m2!1sen!2sph!4v1714441843858!5m2!1sen!2sph" width="100%" height="300" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" loading="lazy"></iframe>
					</div>
				</div>
			</div>
		</div>
		<!-- Contact Us End -->

		<!-- Coryright -->
		<CopyRight />
		<!-- Copyright end -->
	</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Vue from "vue";
import AOS from 'aos';
import MobileHeader from "@/components/ChooseUs.vue";
import ChooseUs from "@/components/ChooseUs.vue";
import TheTesti from "@/components/TheTesti.vue";
import CounterCount from "@/components/CounterCount.vue";
import CopyRight from "@/components/CopyRight.vue";
import EnquiryForm from "@/components/EnquiryForm.vue"

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

export default {
	name: "Home",
	components: {
		// HelloWorld
		MobileHeader,
		ChooseUs,
		TheTesti,
		CounterCount,
		CopyRight,
		EnquiryForm
	},
	data() {
		return {
			activeSticky: false,
			helpU: [
				{
					aniMation: "fade-up",
					deLay: "0",
					image: "images/how-number-1b.jpg",
					alt: "Qualified Traffic",
					sub: "Great marketers know how to target people who are truly interested. We help you create meaningful relationships with your customers and become top of mind.",
				},
				{
					aniMation: "fade-up",
					deLay: "300",
					image: "images/how-number-2b.jpg",
					alt: "Guaranteed Leads",
					sub: "No more canvassing, no more cold-calling – we optimize your funnel for maximum lead generation. We help convert your viewers into your customers.",
				},
				{
					aniMation: "fade-up",
					deLay: "600",
					image: "images/how-number-3b.jpg",
					alt: "Proven ROI",
					sub: "Digital marketing maximizes your return on investment. With a heavy focus on converting the right people, we bring in customers that can, will and want to spend.",
				},
			],
			industry: [
				{
					open: 0,
					image: "images/icon-industry-01.png",
					alt: "Medical",
					text: 'We have experience with specialties such as orthopaedic, obstetrics & gynaecology, cardiology, dermatology, dentistry and more. Your campaign will always be on call 24/7.',
				},
				{
					open: 0,
					image: "images/icon-industry-02.png",
					alt: "Law",
					text: 'Whether you’re a boutique law firm or industry titan, there’ll be no objections once you’re on board.',
				},
				{
					open: 0,
					image: "images/icon-industry-03.png",
					alt: "F&B",
					text: 'Restaurants, private kitchens and food kiosks are part of our repertoire. We’ll make sure you’re seen, heard & tasted.',
				},
				{
					open: 0,
					image: "images/icon-industry-04.png",
					alt: "Beauty",
					text: 'With our assistance, your online presence will be on a permanent glow-up.',
				},
				{
					open: 0,
					image: "images/icon-industry-05.png",
					alt: "Pest Control",
					text: 'Pest Control is often urgently searched for. We get your brand up so you’re always there when it matters.',
				},
				{
					open: 0,
					image: "images/icon-industry-06.png",
					alt: "Automotive",
					text: 'Whether you’re running a vehicle rental business, a new/used car dealership or a workshop, we’ll drive your revenue and fuel your growth.',
				},
			],
			industryToggled: [],
		};
	},
	methods: {
		handleScroll () {
			// console.log(window.scrollY)
			this.activeSticky = (window.scrollY >= 200) ? true : false;
			AOS.refresh();
		},
		toggleIndustry(num) {
			if (this.industryToggled[num]) {
				this.industryToggled[num] = 0
			} else {
				this.industryToggled.forEach((item, index, arr)=> {
					this.industryToggled[index] = 0;
				});
				this.industryToggled[num] = 1;
			}
			console.log(this.industryToggled);
		}
	},
	mounted () {
		document.addEventListener('scroll', this.handleScroll);
		this.industry.forEach(()=>{
			this.industryToggled.push(0);
		});
		// window.addEventListener('load', ()=>{
		//   setTimeout(AOS.refresh(),1000);
		// });
	},
};
</script>

<style>
.scroll-mt-32 {
	scroll-margin-top: 8rem;
}

/* Font Family */
.montserrat-reg {
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
}
.montserrat-med {
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
}
.montserrat-bol {
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
}

/* Max Width */
.maxWidth {
	max-width: 1920px;
}

/* Header Style */
.am-blue {
	color: #4678b4;
}
.am-blue-bg {
	background: #4678b4;
}
.am-dark-bg {
	background: #0f1455;
}
.mar-bottom {
	margin-bottom: 2%;
}

/* counter font size */
.am-blue-dark {
	color: #0f1455;
}

/* Industry */
.cusWidth {
	width: 300px;
}

/* responsive */
@media (min-width: 512px) {
	.mar-bottom {
		margin-bottom: 0;
	}
}

@media (min-width: 768px){
	#customHeight iframe {
		min-height: 490px !important;
	}
}
@media (min-width: 1024px) {
	.conterfont {
		font-size: 120px;
	}
	.paddingLeft {
		padding-left: 10%;
	}
	.bg-banner-wrap, .bg-banner-inner {
		min-height: 840px;
	}
	/* enquiry form */
	/* .enquiry iframe {
		min-height: 550px !important;
	} */
}

@media (min-width: 1500px) {
	.paddingLeft {
		padding-left: 34.53%;
	}
}
</style>
